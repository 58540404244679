import React from 'react'
import styled from 'styled-components'

export const Gutter = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 150 150">
        <g>
            <path d="M146.3,0H3.8C1.7,0,0,1.7,0,3.8v16.6c0,9.9,8.1,18,18,18h10.1v15.9c0,18.8,15.3,34,34,34
                c1.5,0,2.8,1.2,2.8,2.8v12.8c0,0.7,0,1.4,0.1,2.1c0.2,1.9,1.8,3.3,3.7,3.3h23.5c1.9,0,3.5-1.4,3.7-3.3c0.1-0.7,0.1-1.4,0.1-2.1V91
                c0-18.8-15.3-34-34-34c-1.5,0-2.8-1.2-2.8-2.8V38.4H132c9.9,0,18-8.1,18-18V3.8C150,1.7,148.3,0,146.3,0z M14.6,7.5h95.1v3.8
                c0,1.1-0.9,2-2,2H16.6c-1.1,0-2-0.9-2-2V7.5z M7.5,20.4V14c1.1,3.9,4.8,6.8,9.1,6.8h91.2c2.4,0,4.6-0.9,6.2-2.3v1.9
                c0,3.9,1.3,7.5,3.4,10.5H18C12.2,30.9,7.5,26.2,7.5,20.4z M62.1,64.5c14.6,0,26.5,11.9,26.5,26.5v10.7H72.4V91
                c0-5.7-4.6-10.3-10.3-10.3c-14.6,0-26.5-11.9-26.5-26.5V38.4h16.3v15.9C51.9,59.9,56.5,64.5,62.1,64.5z M142.5,20.4
                c0,5.8-4.7,10.5-10.5,10.5c-5.8,0-10.5-4.7-10.5-10.5V7.5h21V20.4z"/>
            <path d="M84.5,113.4c-0.8-1.1-2.1-1.8-3.5-1.8c-1.4,0-2.7,0.7-3.5,1.8l-8.9,12.7c-4.2,6-3.5,14.2,1.7,19.4
                c3,3,6.8,4.4,10.7,4.4c3.9,0,7.8-1.5,10.7-4.4c5.2-5.2,5.9-13.4,1.7-19.4L84.5,113.4z M86.4,140.3c-3,3-7.8,3-10.8,0
                c-2.6-2.6-3-6.8-0.9-9.8l6.3-8.9l6.3,8.9C89.4,133.5,89.1,137.6,86.4,140.3z"/>
        </g>
    </Svg>
)

export const Roofing = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 150 86.1">
        <path d="M149.7,83.8C149.7,83.8,149.7,83.8,149.7,83.8c0.1-0.2,0.1-0.3,0.1-0.4c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3
        c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1
        c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1
        c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-37-55.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.2
        c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.2-0.2
        c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1
        c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0h0h0H85V7.5h3.8
        c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8H56.3c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8H60v15.9H40.7
        c-1.3,0-2.4,0.6-3.1,1.7l-37,55.2c-0.8,1.2-0.8,2.6-0.2,3.9c0.7,1.2,1.9,2,3.3,2h68.6h0c0,0,0,0,0,0h2.9h12.3c0,0,0,0,0,0H131
        c0,0,0,0,0,0c0,0,0,0,0,0h15.2c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0
        c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
        c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0.1-0.1
        c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1
        c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1C149.6,84,149.7,83.9,149.7,83.8z M133,78.6l-20.6-30.8
        c-0.7-1-1.9-1.7-3.1-1.7c-1.3,0-2.4,0.6-3.1,1.7L85.5,78.6h-6.2l29.9-44.7l29.9,44.7H133z M124,78.6H94.5l14.7-22L124,78.6z
        M67.5,7.5h10v15.9h-10V7.5z M42.7,30.9h59.5L70.3,78.6H10.8L42.7,30.9z"/>
     </Svg>
)

export const Garage = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 150 150">
        <path d="M148.2,42.9L76.9,0.5c-1.2-0.7-2.7-0.7-3.8,0L1.8,42.9C0.7,43.6,0,44.8,0,46.1v93c0,6,4.9,10.9,10.9,10.9
            h128.3c6,0,10.9-4.9,10.9-10.9v-93C150,44.8,149.3,43.6,148.2,42.9z M121.3,107.5H28.8v-10h92.5V107.5z M121.3,90H28.8V80h92.5V90z
            M28.8,115h92.5v10H28.8V115z M121.3,72.5H28.8v-10h92.5V72.5z M28.8,132.5h92.5v10H28.8V132.5z M142.5,139.1
            c0,1.9-1.5,3.4-3.4,3.4h-10.4V58.8c0-2.1-1.7-3.8-3.8-3.8H25c-2.1,0-3.8,1.7-3.8,3.8v83.8H10.9c-1.9,0-3.4-1.5-3.4-3.4V48.3L75,8.1
            l67.5,40.1V139.1z"/>
    </Svg>
)
export const Concrete = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 150 150">
        <path d="M145.3,5c-4.3-4.4-10.4-6-16.4-4.3L35.2,27.7c-5.7,1.6-9.9,6-11.4,11.7c-1.5,5.7,0.2,11.6,4.3,15.7
        l31.6,31.5l-4.9,4.5c-6.6-6.5-17.2-6.4-23.8,0.1L5,117.4c-3.2,3.2-5,7.4-5,11.9c0,4.5,1.8,8.8,5,11.9l3.7,3.7
        c3.3,3.3,7.6,4.9,12,4.9c4.3,0,8.7-1.6,12-4.9l26.1-26c3.2-3.2,5-7.4,5-11.9c0-3.8-1.3-7.4-3.6-10.4l5-4.6l28.4,28.3v0
        c3.2,3.1,7.3,4.8,11.6,4.8c1.3,0,2.7-0.2,4.1-0.5c5.7-1.4,10.1-5.6,11.8-11.2l28.4-92.3C151.1,15.5,149.6,9.3,145.3,5z M53.4,113.7
        l-26.1,26c-3.7,3.7-9.7,3.7-13.3,0l-3.7-3.7c-1.8-1.8-2.8-4.1-2.8-6.6c0-2.5,1-4.9,2.8-6.6l26.1-26c1.8-1.8,4.3-2.8,6.7-2.8
        c2.4,0,4.8,0.9,6.7,2.8l3.7,3.7c1.8,1.8,2.8,4.1,2.8,6.6C56.2,109.6,55.2,112,53.4,113.7z M142.1,19.1l-28.4,92.3
        c-0.9,3-3.3,5.3-6.4,6.1c-3.1,0.8-6.3-0.1-8.6-2.4v0L70.5,86.9l25.2-23.5c1.5-1.4,1.6-3.8,0.2-5.3c-1.4-1.5-3.8-1.6-5.3-0.2
        L65.2,81.6L33.4,49.9c-2.3-2.3-3.2-5.5-2.4-8.6c0.8-3.1,3.1-5.5,6.2-6.4L131,7.9c0.9-0.2,1.7-0.4,2.6-0.4c2.4,0,4.6,0.9,6.4,2.7
        C142.2,12.6,143.1,15.9,142.1,19.1z"/>
    </Svg>
)
export const Fencing = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 180 121.9">
        <g>
            <circle cx="25" cy="91.7" r="5.1"/>
            <circle cx="25" cy="43.8" r="5.1"/>
            <circle cx="90" cy="91.7" r="5.1"/>
            <circle cx="90" cy="43.8" r="5.1"/>
            <path d="M178,14.4L157.5,0.8c-1.5-1-3.5-1-5,0L132,14.4c-1.3,0.8-2,2.2-2,3.7v21.1H115V18.2c0-1.5-0.8-2.9-2-3.7
                L92.5,0.8c-1.5-1-3.5-1-5,0L67,14.4c-1.3,0.8-2,2.2-2,3.7v21.1H50V18.2c0-1.5-0.8-2.9-2-3.7L27.5,0.8c-1.5-1-3.5-1-5,0L2,14.4
                c-1.3,0.8-2,2.2-2,3.7v99.2c0,2.5,2,4.5,4.5,4.5h41c2.5,0,4.5-2,4.5-4.5V96.2H65v21.2c0,2.5,2,4.5,4.5,4.5h41c2.5,0,4.5-2,4.5-4.5
                V96.2H130v21.2c0,2.5,2,4.5,4.5,4.5h41c2.5,0,4.5-2,4.5-4.5V18.2C180,16.7,179.2,15.3,178,14.4z M41,112.9H9V20.6L25,9.9l16,10.7
                V112.9z M50,87.2V48.3H65v38.9H50z M106,112.9H74V20.6L90,9.9l16,10.7V112.9z M115,87.2V48.3H130v38.9H115z M171,112.9h-32V20.6
                l16-10.7l16,10.7V112.9z"/>
            <circle cx="155" cy="91.7" r="5.1"/>
            <circle cx="155" cy="43.8" r="5.1"/>
        </g>
    </Svg>
)

export const Decks = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 180 162.8">
        <path d="M175.5,41.3h-9.8V4.5c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5V14L23.3,98.3v-3.8c0-2.5-2-4.5-4.5-4.5
        c-2.5,0-4.5,2-4.5,4.5v36.8H4.5c-2.5,0-4.5,2-4.5,4.5v22.5c0,2.5,2,4.5,4.5,4.5h171c2.5,0,4.5-2,4.5-4.5V45.8
        C180,43.3,178,41.3,175.5,41.3z M23.3,109l133.5-84.3v16.6H147c-2.5,0-4.5,2-4.5,4.5v13.5h-24c-2.5,0-4.5,2-4.5,4.5v13.5H90
        c-2.5,0-4.5,2-4.5,4.5v13.5h-24c-2.5,0-4.5,2-4.5,4.5v13.5H33c-2.5,0-4.5,2-4.5,4.5v13.5h-5.3V109z M171,153.8H9v-13.5h24
        c2.5,0,4.5-2,4.5-4.5v-13.5h24c2.5,0,4.5-2,4.5-4.5v-13.5h24c2.5,0,4.5-2,4.5-4.5V86.3h24c2.5,0,4.5-2,4.5-4.5V68.3h24
        c2.5,0,4.5-2,4.5-4.5V50.3H171V153.8z"/>
    </Svg>
)
export const Windows = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 180 180">
        <g>
            <path d="M175.5,0H4.5C2,0,0,2,0,4.5v171c0,2.5,2,4.5,4.5,4.5h171c2.5,0,4.5-2,4.5-4.5V4.5C180,2,178,0,175.5,0z
                M171,171H9V9h162V171z"/>
            <path d="M19.5,165h141c2.5,0,4.5-2,4.5-4.5v-141c0-2.5-2-4.5-4.5-4.5h-141C17,15,15,17,15,19.5v141
                C15,163,17,165,19.5,165z M156,156H94.5V24H156V156z M24,24h61.5v132H24V24z"/>
        </g>
    </Svg>
)
export const Siding = () => (
    <Svg x="0px" y="0px"
    viewBox="0 0 150 127.5">
        <path d="M146.3,47.5c2.1,0,3.8-1.7,3.8-3.8v-20c0-2.1-1.7-3.8-3.8-3.8h-14.1V3.8c0-2.1-1.7-3.8-3.8-3.8H21.6
        c-2.1,0-3.8,1.7-3.8,3.8V20H3.8C1.7,20,0,21.7,0,23.8v20c0,2.1,1.7,3.8,3.8,3.8h14.1V60H3.8C1.7,60,0,61.7,0,63.8v20
        c0,2.1,1.7,3.8,3.8,3.8h14.1V100H3.8c-2.1,0-3.8,1.7-3.8,3.8v20c0,2.1,1.7,3.8,3.8,3.8h142.5c2.1,0,3.8-1.7,3.8-3.8v-20
        c0-2.1-1.7-3.8-3.8-3.8h-14.1V87.5h14.1c2.1,0,3.8-1.7,3.8-3.8v-20c0-2.1-1.7-3.8-3.8-3.8h-14.1V47.5H146.3z M142.5,40h-28.1V27.5
        h28.1V40z M78.8,40V27.5h28.1V40H78.8z M106.9,80H78.8V67.5h28.1V80z M106.9,120H78.8v-12.5h28.1V120z M43.1,107.5h28.1V120H43.1
        V107.5z M43.1,67.5h28.1V80H43.1V67.5z M43.1,27.5h28.1V40H43.1V27.5z M60.9,100V87.5h28.1V100H60.9z M60.9,60V47.5h28.1V60H60.9z
        M124.7,20H96.6V7.5h28.1V20z M89.1,20H60.9V7.5h28.1V20z M25.3,7.5h28.1V20H25.3V7.5z M7.5,27.5h28.1V40H7.5V27.5z M25.3,47.5
        h28.1V60H25.3V47.5z M7.5,67.5h28.1V80H7.5V67.5z M25.3,87.5h28.1V100H25.3V87.5z M7.5,107.5h28.1V120H7.5V107.5z M142.5,120h-28.1
        v-12.5h28.1V120z M96.6,100V87.5h28.1V100H96.6z M142.5,80h-28.1V67.5h28.1V80z M96.6,60V47.5h28.1V60H96.6z"/>
    </Svg>
)


// Styled Components
const Svg = styled.svg`
    fill:${props => props.theme.color.blue.primary};
    height:100%;
    width:100%;
`