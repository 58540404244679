import { graphql, useStaticQuery } from 'gatsby'

export default () => {
    const { allWordpressWpService: {
        nodes
    }} = useStaticQuery(graphql`
        query useServices {
            allWordpressWpService {
                nodes {
                    slug
                    acf {
                        title
                        subtitle
                        excerpt
                        icon
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1000, maxHeight: 650, quality: 65) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return nodes
}