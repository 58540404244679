import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

// Components
import {
    Gutter,
    Roofing,
    Garage,
    Concrete,
    Fencing,
    Decks,
    Windows,
    Siding
} from './icons'

// Higher Order Component
const Icon = ({
    icon
}) => {
    switch(icon) {
        case `roofing`: 
            return <Roofing/>
        case `gutter`:
            return <Gutter/>
        case `garage`:
            return <Garage/>
        case `concrete`:
            return <Concrete/>
        case `fencing`:
            return <Fencing/>
        case `decks`:
            return <Decks/>
        case `windows`:
            return <Windows/>
        case `siding`:
            return <Siding/>
        default:
            return <Roofing/>
    }
}


export default ({
    icon
}) => {
    return (
        <Wrapper>
            <Icon icon={icon}/>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    height:2.75rem;
    width:2.75rem;
    padding:0.6rem;
    border-radius:100%;
    display:flex;
    ${props => `
        background-color:${
            lighten(0.35, props.theme.color.blue.primary)
        };
    `}
`