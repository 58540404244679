import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

// Assets
import BackgroundImage from '../../images/background/cubes.svg'

// Components
import Container from '../../components/container'

export default ({
    title,
    description
}) => {
    return (
        <Header>
            <Background />
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:6.5rem;
                    max-width:800px;
                `}
            >
                <Title>{title}</Title>
                <Description>{description}</Description>
                <List>
                    <Button onClick={() => scrollTo('#service-list')} rel="nofollow">
                        {`View Our Services`}
                    </Button>
                    <Button as={Link} to={`/quote/`}>
                        {`Request a Quote`}
                    </Button>
                </List>
            </Container>
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    text-align:center;
    position:relative;
    box-shadow: 
        inset 0px 2.5rem 1rem -1.5rem rgb(255,255,255),
        inset 0px -2.5rem 1rem -1.5rem rgb(245,245,250)
    ;
    @media (min-width:992px) {
        box-shadow: 
            inset 0px 6rem 1rem -4.5rem rgb(255,255,255),
            inset 0px -6rem 1rem -4.5rem rgb(245,245,250)
        ;
    }
`
const Background = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-image:url(${BackgroundImage}), linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
    background-size:contain;
    background-repeat:no-repeat;
    text-align:center;
    z-index:-1;
    opacity:0.45;
    background-size: 100% auto;
    background-position-y:100%;
    @media (min-width:992px) {
        background-position-y:calc(50% + 3rem);
    }
`
const Title = styled.h1`
    color: ${props => props.theme.color.blue.dark};
    font-size:1.5rem;
    @media (min-width:992px) {
        font-size:3rem;
        text-transform:uppercase;
    }
`
const Description = styled.p`
    font-size:1rem;
    padding:0.5rem 0 1.5rem 0;
    @media (min-width:992px) {
        padding:0.5rem 0 3rem 0;
        font-size:1.25rem;
    }
`
const List = styled.div`
    display:flex;
    justify-content:center;
`
const Button = styled.a`
    letter-spacing:1px;
    font-weight:bold;
    padding:0.5rem;
    font-size:0.85rem;
    cursor:pointer;
    ${props => `
        &:first-child {
            background-color:#fff;
            color:${props.theme.color.blue.dark};
            box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
            margin-right:0.75rem;
            @media (min-width:992px) {
                margin-right:1.5rem;
            }
        }
        &:last-child {
            background-color: ${props.theme.color.blue.primary};
            color:#fff;
            box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        }
    `}
    @media (min-width:992px) {
        font-size:1rem;
        padding: 0.75rem 1.5rem;
    }
    &:hover {
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0,0,0,.12)
    }
`