import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../components/container'
import Service from './service/index'

export default ({ services }) => {
    return (
        <Section id="service-list">
            <Container
                sm={`
                    display:grid;
                    padding-bottom:3rem;
                    grid-column-gap:1.5rem;
                    grid-row-gap:0.75rem;
                `}
                lg={`
                    grid-row-gap:1.5rem;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                `}
            >
                {services.map(({
                    slug,
                    acf: {
                        title,
                        subtitle,
                        excerpt,
                        icon
                    },
                    featured_media: {
                        localFile: {
                            childImageSharp: {
                                fluid
                            }
                        }
                    }
                }, index) => (
                    <Service
                        key={index}
                        title={title}
                        subtitle={subtitle}
                        description={excerpt}
                        slug={slug}
                        icon={icon}
                        background={fluid}
                    />
                ))}
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    background-color:#f5f5fa;
`