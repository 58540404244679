import React from 'react'
import styled from 'styled-components'

// Components
import Icon from './icon/index'

export default ({
    title,
    subtitle,
    background,
    icon
}) => {
    return (
        <Header>
            <Icon icon={icon}/>
            <Content>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Content>
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    position:relative;
    display:grid;
    grid-template-columns: 2.75rem minmax(0, 1fr);
    grid-column-gap:0.5rem;
    align-items:center;
    padding:0.5rem;
    @media (min-width:992px) {
        padding:1rem;
        grid-column-gap:1rem;
    }
`
const Content = styled.div`
`
const Title = styled.h2`
    font-size:1rem;
    line-height:1;
    margin-bottom:0.25rem;
    color:${props => props.theme.color.blue.dark};
    @media (min-width:992px) {
        font-size:1.2rem;
    }
`
const Subtitle = styled.p`
    line-height:1;
    font-size:0.85rem;
    @media (min-width:992px) {
        font-size:0.9rem;
    }
`