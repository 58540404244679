import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// Hooks
import { usePlaceholderBg } from '../../../hooks/placeholders/usePlaceholderBg'

// Components
import Header from './header'

export default ({
    title,
    subtitle,
    description,
    slug,
    icon,
    background
}) => {
    return (
        <Article>
            <Header
                title={title}
                subtitle={subtitle}
                icon={icon}
            />
            <Image 
                fluid={background ? background : usePlaceholderBg()}
            />
            <Description>{description}</Description>
            <Cta>
                <Button to={`/${slug}/`}>{`Learn More`}</Button>
                <Button to={`/quote/`}>{`Get a Free Quote`}</Button>
            </Cta>
        </Article>
    )
}

// Styled Components
const Article = styled.article`
    background-color: #fff;
    box-shadow:rgba(14,30,37,0.12) 0px 2px 4px 0px;
    border-radius: 0.25rem;
    overflow:hidden;
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    position:relative;
    top:0;
    transition: all .2s;
    @media (min-width:992px) {
        &:hover {
            top: -7px;
            box-shadow: rgba(0, 0, 0, 0.15) -2px 8px 22px 0px;
        }
    }
`
const Description = styled.p`
    padding:0.5rem;
    font-size:0.85rem;
    text-align:center;
    @media (min-width:992px) {
        text-align:left;
        padding:1rem;
        font-size:1rem;
    }
`
const Cta = styled.div`
    width:100%;
    display:grid;
    margin-top:auto;
    justify-self:flex-end;
    grid-template-columns:minmax(0, 1fr);
    grid-row-gap: 1px;
    grid-column-gap: 1px;
    background-color:rgba(0,0,0,0.1);
    border-top:1px solid rgba(0,0,0,0.1);
    @media (min-width:992px) {
        grid-template-columns:repeat(2, minmax(0, 1fr));
    }
`
const Button = styled(Link)`
    text-align:center;
    background-color:#fff;
    color:${props => props.theme.color.blue.primary};
    font-weight:bold;
    padding:0.5rem 0;
    font-size: 0.85rem;
    &:hover {
        background-color:#f5f5fa;
    }
    &:last-child {
        display:none;
    }
    @media (min-width:992px) {
        font-size: 0.9rem;
        padding:1rem;
        &:last-child {
            display:block;
        }
    }
`
const Image = styled(Img)`
    z-index:0;
    height:130px;
    width:100%;
    &:before {
        content: '';
        background-color:rgba(0,0,0,0.3);
        z-index:1;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
    @media (min-width:992px) {
        height:225px;
    }
`