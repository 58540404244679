import React from 'react'

// Components
import Header from './header'
import Intro from './intro'

// Hooks
import useServices from '../../hooks/services/useServices'

export default () => {
    return (
        <React.Fragment>
            <Header 
                title={`Residential Remodeling & Construction Services`}
                description={`Eastern Iowa's Most Reliable and Trusted Residential Construction Services.`}
            />
            <Intro
                services={useServices()}
            />
        </React.Fragment>
    )
}