import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Content from '../templates/services/index'

export default () => {
    return (
        <Layout>
            <SEO title="Our Services" 
                description="J.P. Home Builders Corp. has been trusted by thousands across Eastern with top-tier home construction and remodeling services at afforadble prices."
            />
            <Content/>
        </Layout>
    )
}
